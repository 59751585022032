<template>
      <docs></docs>
</template>

<script>

 import docs from '@/components/users.vue';

    export default {
        components: {
          docs
        },
      mounted(){
        console.log("Dashboard.vue mounted() starting. docs=", docs);
      },
      computed: {
      name() {
          return this.$store.state.user.name;
      },
      email() {
          return this.$store.state.user.email;
      }
     },methods: {
        logout() {
          this.$store.dispatch('logout');
        }
      }
    }    

  </script>
